import React, { createContext, useState, useContext } from 'react'

const LoadingContext = createContext()

export const useLoading = () => useContext(LoadingContext)

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)

    return (
        <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
            {children}
        </LoadingContext.Provider>
    )
}
