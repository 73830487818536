import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './reducers/store'
import { Provider } from 'react-redux'
import { LoadingProvider } from './components/Loader/LoaderContext'
import { ViewCounterContextProvider } from './components/ViewCounter/ViewContextProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <LoadingProvider>
            <ViewCounterContextProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </ViewCounterContextProvider>
        </LoadingProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
